import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../common/state";
import { DatasetCardGrid } from "./DatasetCardGrid";
import { selectError } from "../../common/state/errorReducer";
import {
    Label,
    mergeStyleSets,
    Stack,
    MessageBar,
    MessageBarType,
    Dropdown,
    IDropdownOption,
    IDropdownStyles,
    Icon,
    IconButton
} from "@fluentui/react";
import { NoDatasetsAccess } from "./NoDatasetsAccess";
import { DataSetSummary } from "./DataSetSummary.types";
import SuggestionSearchBox, { Suggestion } from "../Search/SuggestionSearchBox";
import { dataCatalogClient } from "../../common/clients";
import { SortByType } from "./DataSetDetail.types";
import axios, { CancelTokenSource } from "axios";
import useDebounce from "../../common/hooks/useDebounce";
import {
    clearSuggestions,
    searchFilter,
    setSearchFilter,
    setSuggestionClicked,
    setFilteredDatasets,
    setSuggestions,
    setPathSuggestions,
    setColumnSuggestions
} from "../Search/slice";
import { getAllFavoriteDatasets, selectFavoriteDatasets } from "../../common/state/favoriteDatasetReducer";

export type SearchByPathResult = {
    path: string;
    id: number;
    type: string;
};

type ColumnType = "ADLS" | "SDW" | "Kusto";

export type SearchByColumnResult = {
    columnName: string;
    value: Type[];
};

export type Type = {
    datasetId: number;
    types: ColumnType[];
};

export type SortByProps = {
    type: SortByType;
    toggleValue: boolean;
};

export function DatasetsView({
    my,
    allDataSets,
    isLoading
}: {
    my: boolean;

    allDataSets?: DataSetSummary[];
    isLoading: boolean;
}) {
    const { specificError } = useAppSelector(selectError);

    const [loading, setLoading] = useState<boolean>(false);

    const [dataSets, setDataSets] = useState<DataSetSummary[]>();
    // final state value to be used for sorting
    const [sortByProps, setSortByProps] = useState<SortByProps>({ type: SortByType.MyDataset, toggleValue: true });
    const {
        searchFilter: filter,
        suggestionClicked,
        suggestions,
        filteredDatasets,
        pathSuggestions,
        columnSuggestions
    } = useAppSelector(searchFilter);
    const { favoriteDatasets } = useAppSelector(selectFavoriteDatasets);

    const pathTokenSource = useRef<CancelTokenSource | null>(null);
    const columnTokenSource = useRef<CancelTokenSource | null>(null);
    const dispatch = useAppDispatch();

    const debouncePathSearch = useDebounce(async () => {
        if (!filter) return;
        // Cancel the previous request before making a new request
        if (pathTokenSource.current) {
            pathTokenSource.current.cancel();
        }
        // Create a new CancelToken
        pathTokenSource.current = axios.CancelToken.source();
        try {
            await getPaths(filter!);
        } catch (error) {
            if (axios.isCancel(error)) {
            } else {
                // handle error
                console.log(error);
            }
        }
    }, 700);

    const debounceColumnSearch = useDebounce(async () => {
        if (!filter) return;
        if (columnTokenSource.current) {
            columnTokenSource.current.cancel();
        }
        // Create a new CancelToken
        columnTokenSource.current = axios.CancelToken.source();

        try {
            await getColumns(filter!);
        } catch (error) {
            if (axios.isCancel(error)) {
            } else {
                // handle error
                console.log(error);
            }
        }
    }, 700);

    async function getPaths(query: string) {
        const response = await dataCatalogClient.get<SearchByPathResult[]>(`datasets/searchByPath/?path=${query}`, {
            cancelToken: pathTokenSource.current?.token
        });
        if (!suggestionClicked) dispatch(setPathSuggestions(response.data));
    }

    async function getColumns(query: string) {
        const response = await dataCatalogClient.get<SearchByColumnResult[]>(
            `datasets/searchByColumn/?query=${query}`,
            { cancelToken: columnTokenSource.current?.token }
        );

        if (!suggestionClicked) dispatch(setColumnSuggestions(response.data));
    }

    useEffect(() => {
        setLoading(false);
        if (pathSuggestions && pathSuggestions.length > 0 && suggestions && filteredDatasets && dataSets) {
            // first remove the path search suggestions
            const pathSearchRemoved = suggestions.filter((x) => x.type !== "Endpoint");

            const newSug = pathSuggestions.map((x): Suggestion => ({ value: x.path, type: "Endpoint", extra: x.type }));
            const uniqueNewSug = newSug.filter((v, i, a) => a.findIndex((t) => t.value === v.value) === i);

            dispatch(setSuggestions([...pathSearchRemoved, ...uniqueNewSug]));

            // filter the datasets based on the path search but exclude the ones already in the filtered dataset
            dispatch(
                setFilteredDatasets([
                    ...filteredDatasets,
                    ...dataSets.filter(
                        (ds) =>
                            filteredDatasets.findIndex((x) => x.id == ds.id) === -1 &&
                            pathSuggestions.findIndex((x) => x.id == ds.id) !== -1
                    )
                ])
            );
        }
    }, [pathSuggestions]);

    useEffect(() => {
        if (my && allDataSets) {
            setDataSets(allDataSets.filter((x) => x.my));
        } else if (allDataSets) setDataSets(allDataSets);

        // Get Favorite Dataset details for the current user
        dispatch(getAllFavoriteDatasets());
    }, [allDataSets, my]);

    useEffect(() => {
        setLoading(false);

        if (columnSuggestions && dataSets && suggestions && filteredDatasets) {
            const columnSearchRemoved = suggestions.filter((x) => x.type !== "Column");

            const newSug = columnSuggestions.map(
                (x): Suggestion => ({
                    value: x.columnName,
                    extra: Array.from(new Set(x.value.flatMap((y) => y.types))).join(", "),
                    type: "Column"
                })
            );
            dispatch(setSuggestions([...columnSearchRemoved, ...newSug]));

            const dsIds = Array.from(new Set(columnSuggestions.flatMap((x) => x.value).map((x) => x.datasetId)));

            dispatch(
                setFilteredDatasets([
                    ...filteredDatasets,
                    ...dataSets.filter(
                        (ds) =>
                            filteredDatasets.findIndex((x) => x.id == ds.id) === -1 &&
                            dsIds.findIndex((x) => x == ds.id) !== -1
                    )
                ])
            );
        }
    }, [columnSuggestions]);

    useEffect(() => {
        if (specificError["getAllDataSets"]) {
            dispatch(setFilteredDatasets([]));
        }
    }, []);
    useEffect(() => {
        if (dataSets && !filter) {
            dispatch(setFilteredDatasets(dataSets));
        }
    }, [dataSets]);

    // merging favorites with current view of datasets
    useEffect(() => {
        try {
            if (filteredDatasets && favoriteDatasets) {
                let dSets = filteredDatasets.map((e) => ({
                    ...e,
                    isFavorite: favoriteDatasets.includes(e.id) ? true : false
                }));

                dispatch(setFilteredDatasets(dSets));
            }
        } catch (e) {}
    }, [favoriteDatasets]);

    useEffect(() => {
        if (dataSets) {
            if (!filter || filter.trim() === "") {
                // All dataset view merging with Fav / UnFav results
                try {
                    if (dataSets && favoriteDatasets) {
                        let dSets = dataSets.map((e) => ({
                            ...e,
                            isFavorite: favoriteDatasets.includes(e.id) ? true : false
                        }));

                        dispatch(setFilteredDatasets(dSets));
                        dispatch(clearSuggestions());
                    }
                } catch (e) {}
            } else {
                if (filter.length > 2) {
                    setLoading(true);
                    debouncePathSearch();
                    debounceColumnSearch();
                } else {
                    dispatch(setColumnSuggestions([]));
                    dispatch(setPathSuggestions([]));
                }

                const datasetNameResults = dataSets.filter(
                    (ds) => ds.name.toLowerCase().trim().indexOf(filter.trim().toLowerCase()) != -1
                );

                const accessPackageResults = dataSets.filter(
                    (ds) => ds.accessPackageName.toLowerCase().trim().indexOf(filter.trim().toLowerCase()) != -1
                );

                const tagResults = dataSets.filter((ds) => tagSearch(ds.datasetTags, filter));

                // combine and de-duplicate
                if (columnSuggestions.length < 1 && pathSuggestions.length < 1) {
                    const allResults = [...datasetNameResults, ...accessPackageResults, ...tagResults].filter(
                        (value, index, self) => index === self.findIndex((t) => t.id === value.id)
                    );
                    // Filterd view merging with Fav / UnFav results
                    try {
                        if (allResults && favoriteDatasets) {
                            let dSets = allResults.map((e) => ({
                                ...e,
                                isFavorite: favoriteDatasets.includes(e.id) ? true : false
                            }));

                            dispatch(setFilteredDatasets(dSets));
                        }
                    } catch (e) {}
                }
                const nameSuggestions: Suggestion[] = datasetNameResults.map((x) => ({
                    value: x.name,
                    type: "Name"
                }));

                const accessPackageSuggestion: Suggestion[] = accessPackageResults
                    .filter(
                        (value, index, self) =>
                            index === self.findIndex((t) => t.accessPackageName === value.accessPackageName)
                    )
                    .map((x) => ({
                        value: x.accessPackageName,
                        type: "Access Package"
                    }));

                const tagSuggestions = Array.from(
                    new Set(
                        tagResults
                            .flatMap((x) => x.datasetTags)
                            .filter((x) => x.toLowerCase().trim().indexOf(filter.trim().toLowerCase()) != -1)
                    )
                );

                dispatch(
                    setSuggestions([
                        ...tagSuggestions.map((x): Suggestion => ({ value: x, type: "Tag" })),
                        ...nameSuggestions,
                        ...accessPackageSuggestion
                    ])
                );
            }
        }
    }, [filter, dataSets]);

    function handleSuggestionClick(suggestion: Suggestion) {
        columnTokenSource.current?.cancel();
        pathTokenSource.current?.cancel();
        dispatch(setSuggestionClicked(true));
        dispatch(setSuggestions([suggestion]));
        if (dataSets) {
            if (suggestion.type === "Tag") {
                const tagds = dataSets.filter(
                    (x) =>
                        x.datasetTags &&
                        x.datasetTags.map((x) => x.toLocaleLowerCase()).includes(suggestion.value.toLocaleLowerCase())
                );

                if (tagds) {
                    dispatch(setFilteredDatasets(tagds));
                }
            } else if (suggestion.type === "Name") {
                const nameDs = dataSets.find(
                    (x) => x.name.toLocaleLowerCase() === suggestion.value.toLocaleLowerCase()
                );

                if (nameDs) {
                    dispatch(setFilteredDatasets([nameDs]));
                }
            } else if (suggestion.type === "Endpoint") {
                if (pathSuggestions) {
                    const endpoints = pathSuggestions.filter(
                        (x) => x.path === suggestion.value && x.type === suggestion.extra
                    );
                    const endpointDs = dataSets.filter((x) => endpoints.map((x) => x.id).includes(x.id));

                    if (endpointDs) {
                        dispatch(setFilteredDatasets(endpointDs));
                    }
                }
            } else if (suggestion.type === "Access Package") {
                const ds = dataSets.filter((x) => x.accessPackageName === suggestion.value);

                if (ds && ds.length > 0) {
                    dispatch(setFilteredDatasets(ds));
                }
            } else if (suggestion.type === "Column") {
                if (columnSuggestions) {
                    const dsIds = new Set(
                        columnSuggestions
                            .filter((x) => x.columnName === suggestion.value)
                            .flatMap((x) => x.value)
                            .map((x) => x.datasetId)
                    );
                    if (dsIds && dsIds.size > 0) {
                        dispatch(setFilteredDatasets(dataSets.filter((x) => dsIds.has(x.id))));
                    }
                }
            }
        }
    }

    // Filtering dataset results based on search text - Search by Tags
    function tagSearch(tags: string[], searchText: string) {
        let index = -1;
        if (searchText) {
            index = tags.findIndex((element) => {
                return element.toLowerCase().indexOf(searchText.trim().toLowerCase()) != -1;
            });
        }
        return index != -1;
    }

    const classNames = mergeStyleSets({
        root: {
            minWidth: "375px",

            marginTop: 10
        },
        stack: {
            padding: "0px 20px"
        }
    });

    // Sorting related code
    const sortDropdownStyles: Partial<IDropdownStyles> = {
        dropdown: { width: 180 }
    };

    // Sort options for dropdown
    const options: IDropdownOption[] = [
        { key: SortByType.MyDataset, text: "MyDatasets" },
        { key: SortByType.Views, text: "Views" },
        { key: SortByType.Incidents, text: "Active Incidents" },
        { key: SortByType.MyFavorites, text: "Favorites" }
    ];

    const onChangeSortBy = (
        _event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
        _index?: number
    ): void => {
        setSortByProps({ type: option?.key as SortByType, toggleValue: true });
    };

    // Sorting compare function
    function compare(a, b) {
        switch (sortByProps.type) {
            case SortByType.MyDataset:
                return Number(sortByProps.toggleValue ? b.my : a.my) - Number(sortByProps.toggleValue ? a.my : b.my);
            case SortByType.Views:
                return (
                    Number(sortByProps.toggleValue ? b.hitCount : a.hitCount) -
                    Number(sortByProps.toggleValue ? a.hitCount : b.hitCount)
                );
            case SortByType.Incidents:
                return (
                    Number(
                        sortByProps.toggleValue ? Object.keys(b.activeICMs).length : Object.keys(a.activeICMs).length
                    ) -
                    Number(
                        sortByProps.toggleValue ? Object.keys(a.activeICMs).length : Object.keys(b.activeICMs).length
                    )
                );
            case SortByType.MyFavorites:
                return (
                    Number(sortByProps.toggleValue ? b.isFavorite : a.isFavorite) -
                    Number(sortByProps.toggleValue ? a.isFavorite : b.isFavorite)
                );
        }
    }

    function renderSortByOption(option) {
        return (
            <>
                <Icon iconName="info" title={getToolTipText(option?.key)} style={{ paddingRight: 5 }}></Icon>{" "}
                <p aria-label={option?.text}>{option?.text}</p>
            </>
        );
    }

    function getToolTipText(type: SortByType) {
        switch (type) {
            case SortByType.MyDataset:
                return "The list of datasets the user has access to is displayed in ascending/descending order based on selection made.";
            case SortByType.Views:
                return "The list of datasets based on frequency of access in ascending/descending order based on selection made.";
            case SortByType.Incidents:
                return "The list of datasets with active Incidents in production in ascending/descending order based on selection made.";
            case SortByType.MyFavorites:
                return "The list of favorite datasets in ascending/descending order based on selection made.";
        }
    }

    return (
        <Stack>
            <Stack
                style={{ margin: "20px 0 50px 0" }}
                horizontal
                horizontalAlign="space-between"
                className={classNames.stack}
                tokens={{ childrenGap: 12 }}
            >
                <Stack.Item style={{ width: "250px" }}>
                    <Stack horizontal style={{ marginTop: 10 }}>
                        <Stack.Item style={{ width: "58px" }}>
                            <Label style={{ marginRight: 10, width: "58px" }}>Sort by</Label>
                        </Stack.Item>
                        <Stack.Item>
                            <Dropdown
                                placeholder="Select an option"
                                label=""
                                options={options}
                                styles={sortDropdownStyles}
                                selectedKey={sortByProps.type}
                                onChange={onChangeSortBy}
                                onRenderOption={(option) => {
                                    return renderSortByOption(option);
                                }}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <IconButton
                                ariaLabel={"Reorder"}
                                iconProps={{ iconName: sortByProps.toggleValue ? "SortUp" : "SortDown" }}
                                aria-label="Emoji"
                                style={{
                                    position: "absolute",
                                    left: 193,
                                    marginTop: 3,
                                    height: "26.5px",
                                    marginLeft: 5,
                                    backgroundColor: "rgb(243, 242, 241)"
                                }}
                                onClick={() =>
                                    setSortByProps({ type: sortByProps.type, toggleValue: !sortByProps.toggleValue })
                                }
                            />
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
                <Stack.Item style={{ width: "50%" }} align="center">
                    <SuggestionSearchBox
                        className={classNames.root}
                        onSuggestionClicked={handleSuggestionClick}
                        inProgress={loading}
                        onChange={(_, newValue) => {
                            dispatch(setSuggestionClicked(false));
                            dispatch(setSearchFilter(newValue?.trim()));
                        }}
                        debounceTime={500}
                    ></SuggestionSearchBox>
                </Stack.Item>

                <Stack style={{ width: "25%" }} tokens={{ childrenGap: 10 }} horizontal horizontalAlign="end">
                    <Stack.Item>
                        <Label>Count: </Label>
                    </Stack.Item>
                    <Stack.Item>
                        <Label>{filteredDatasets?.length || 0}</Label>
                    </Stack.Item>
                </Stack>
            </Stack>

            {specificError["getAllDataSets"] && (
                <MessageBar messageBarType={MessageBarType.error}>{specificError["getAllDataSets"].message}</MessageBar>
            )}
            <DatasetCardGrid
                dataSets={filteredDatasets}
                loading={isLoading}
                error={""}
                sortByProps={sortByProps}
                isCollectionView={false}
                sortCompare={compare}
            />

            {my && !isLoading && (!dataSets || dataSets.length === 0) && !specificError["getAllDataSets"] && (
                <NoDatasetsAccess />
            )}
        </Stack>
    );
}
