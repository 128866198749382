import {
    FontWeights,
    getTheme,
    IButtonStyles,
    IconButton,
    IIconProps,
    mergeStyleSets,
    Modal,
    Spinner
} from "@fluentui/react";
import { FunctionComponent } from "react";

interface IModelPopupProps {
    headerText: string;
    content?: any;
    isLoading: boolean;
    onDismiss: (val: boolean) => void;
    customWidth?: number;
    loadingLabel?: string;
}

export const SipMonModal: FunctionComponent<IModelPopupProps> = (props: IModelPopupProps) => {
    const { headerText, isLoading, content, customWidth, loadingLabel } = props;

    return (
        <>
            <Modal
                titleAriaId={headerText}
                isOpen={true}
                onDismiss={() => props.onDismiss(false)}
                isBlocking={true}
                styles={{ scrollableContent: { overflowY: "none" }, main: { width: customWidth } }}
            >
                <div className={contentStyles.header}>
                    <span id={"header_" + headerText}>{headerText}</span>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel="Close popup modal"
                        onClick={() => props.onDismiss(false)}
                    />
                </div>
                <div>
                    {isLoading ? (
                        <>
                            <b>
                                <Spinner label={loadingLabel ? loadingLabel : "Please wait, loading..."} />
                            </b>
                        </>
                    ) : (
                        content
                    )}
                </div>
            </Modal>
        </>
    );
};
const theme = getTheme();
const contentStyles = mergeStyleSets({
    header: [
        theme.fonts.xLargePlus,
        {
            flex: "1 1 auto",
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: "flex",
            alignItems: "center",
            fontWeight: FontWeights.semibold,
            padding: "12px 12px 14px 24px"
        }
    ]
});
const cancelIcon: IIconProps = { iconName: "Cancel" };
const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: "auto",
        marginTop: "4px",
        marginRight: "2px"
    },
    rootHovered: {
        color: theme.palette.neutralDark
    }
};
