export default (
    <p>
        As you should be aware, in connection with your role in the MSFT Security & Risk programs, you will need access
        to certain Microsoft confidential information that is extremely sensitive. We're taking this opportunity to
        remind you that as part of your employment with Microsoft, you signed a nondisclosure provision as part of your
        Microsoft Corporation Employee Agreement and are bound by Microsoft's confidential information policy located on
        mspolicy. Your Access to SIP is limited to Security and Risk Purposes Only: You agree to use the data for
        Security and Risk purposes only and in accordance with Security and Privacy Policies and Standards including the
        Microsoft Privacy Standard (MPS). If the original purpose changes you, the requestor, must reinitiate a privacy
        review by contacting the Privacy team at CSEOPT@microsoft.com. What is your obligation? You have agreed to
        protect Microsoft's confidential information. That means you may not share Microsoft's confidential information
        with anyone outside Microsoft, unless you are required to do so by a court order, to comply with other
        government demands that have the force of law, or to meet your fiduciary duties in your legal or financial role
        at Microsoft. Even within Microsoft, you should only share confidential information on a "need to know" basis
        with people you are 100% sure already know about the project. If you are not certain if someone within Microsoft
        "needs to know," you should check with the project's Accountable Owners (AO), the project's Confidentiality
        Program Manager(CPM) or the person who asked you to sign this form. You may also use this application to look up
        individuals by Alias to ascertain whether they are already "tented" or approved for this program. We're trusting
        you by sharing this information with you. You must not disclose, publish, or otherwise reveal Microsoft's
        confidential information to your friends, family, or any other third party. What information should you keep
        confidential? Assume that all Microsoft information you receive should be treated as confidential. Some specific
        examples include: - All SIP Data is Data Classified as Highly Confidential or Confidential as outlined in
        Microsoft's annual Privacy 101 training. All SIP Data must be treated as classified, consistent with Privacy 101
        training for the current fiscal year. You attest to having taken Privacy 101 training in the current fiscal
        year. - Upcoming products, features, prototypes, design specs and related documents. - Business, marketing, or
        PR plans for upcoming products. This includes any marketing materials such as presentations, videos, photos,
        etc. - Product release dates. We may provide you with additional specific instructions on security of certain
        confidential information, which you must follow. What should you avoid? Do NOT: - Blog about confidential
        information you receive, including any aspect of upcoming Microsoft products, prototypes, or new projects or
        their codenames. - Tweet or update social networking sites with information about confidential projects. - Post
        screenshots or video clips of upcoming Microsoft products. - Allow any photos to be taken of upcoming Microsoft
        products or prototypes. When introducing products to new audiences or working with external parties, cameras
        should be forbidden, and cell phone use restricted. In large group settings, cell phones should be prohibited. -
        Discuss confidential information in discussion forums, emails, or podcasts, or in non-secure public areas (for
        example, cafeterias, airports, shuttle vans, and so on.) - Talk with the media or press about any aspect of
        upcoming Microsoft products or prototypes. This includes bloggers, newspaper reporters, and television
        reporters. - Reveal the release date of upcoming Microsoft products. - Allow third parties to access any
        confidential information. Third parties include your family and your friends. You are responsible to maintain
        the confidentiality of the information you receive. What happens if you breach your confidentiality obligation?
        Microsoft will take appropriate action, which may include: - Disciplinary action up to and including termination
        of employment. - Unauthorized disclosure of confidential information may also lead to civil liability or
        criminal prosecution.
    </p>
);
