import { useState, useCallback, useEffect, FormEvent } from "react";
import StandardForm from "../../common/components/StandardForm";
import {
    Checkbox,
    ChoiceGroup,
    DefaultButton,
    FontWeights,
    IChoiceGroupOption,
    Label,
    Link,
    PrimaryButton,
    Separator,
    Stack,
    TextField
} from "@fluentui/react";
import { useAppDispatch } from "../../common/state";
import { requestAccess } from "./slice";
import { RolePlatform } from "./AccessRequest.types";
import ConfidentialityAgreementDialog from "../../common/components/ConfidentialityAgreement/ConfidentialityAgreementDialog";

const UserAccessRequestForm = ({
    closeModal,
    platformDetails
}: {
    closeModal: () => void;
    platformDetails: RolePlatform[];
}) => {
    const [isAgreed, setIsAgreed] = useState(false);
    const [justification, setJustification] = useState("");
    const [selectedAccessPackage, setSelectedAccessPackage] = useState<string>();
    const [platformOptions, setPlatformOptions] = useState<IChoiceGroupOption[]>([]);
    const [showAgreement, setShowAgreement] = useState(false);

    const dispatch = useAppDispatch();

    function onRequestAccess() {
        dispatch(requestAccess(justification));
        closeModal();
    }

    const subTextStyle = {
        root: {
            size: 12,
            color: "gray",
            fontWeight: FontWeights.regular,
            padding: 0
        }
    };

    useEffect(() => {
        const options = platformDetails.map((platform): IChoiceGroupOption => {
            return {
                key: platform.accessPackageId,
                text: `${platform.displayName} - ${platform.description}`
            };
        });

        setPlatformOptions(options);
    }, [platformDetails]);
    const onAccessPackageChange = useCallback(
        (_ev?: FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
            option && setSelectedAccessPackage(option.key);
        },
        []
    );

    return (
        <StandardForm style={{ zIndex: 9999 }} widthPercent={50} mediumWidthPercent={80} smallWidthPercent={90}>
            <Stack tokens={{ childrenGap: 10 }}>
                <Stack tokens={{ childrenGap: 10 }}>
                    <ChoiceGroup
                        selectedKey={selectedAccessPackage}
                        options={platformOptions}
                        onChange={onAccessPackageChange}
                        label="Select a platform to request access to:"
                    />
                    <TextField
                        multiline={true}
                        value={justification}
                        onChange={(_e, value) => setJustification(value || "")}
                        label="Buiness Justification"
                    />

                    <Label styles={subTextStyle} style={{ clear: "both", paddingTop: 5 }}>
                        You must view and agree the terms of the Confidentiality Agreement linked in the description. By
                        selecting yes, stating that you have read and agree to the terms of the Confidentiality
                        Agreement.
                        <Link role="button" onClick={() => setShowAgreement(true)}>
                            SIP Confidentiality Agreement
                        </Link>
                    </Label>
                    <Stack horizontal>
                        <Checkbox
                            id={"chkAgreement"}
                            styles={{ root: { float: "left" } }}
                            checked={isAgreed}
                            onChange={(_e, _checked) => {
                                setIsAgreed(!isAgreed);
                            }}
                        />

                        <Label style={{ padding: 0 }} required>
                            Read Confidentiality Agreement?
                        </Label>
                    </Stack>
                </Stack>
                <Separator />
                <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 10 }}>
                    <PrimaryButton
                        text="Submit"
                        onClick={onRequestAccess}
                        disabled={!isAgreed || !justification || !selectedAccessPackage}
                    />
                    <DefaultButton text="Cancel" onClick={closeModal} />
                </Stack>
            </Stack>
            <ConfidentialityAgreementDialog hidden={!showAgreement} onDismiss={() => setShowAgreement(false)} />
        </StandardForm>
    );
};

export default UserAccessRequestForm;
