import { FontWeights, IButtonStyles, IStackProps, getTheme, mergeStyleSets } from "@fluentui/react";

const theme = getTheme();
export const contentStyles = mergeStyleSets({
    container: {
        display: "flex",
        flexFlow: "column nowrap",
        alignItems: "stretch"
    },
    header: [
        theme.fonts.xLarge,
        {
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            fontWeight: FontWeights.semibold,
            padding: "12px 12px 14px 24px",
            width: "100%"
        }
    ],
    heading: {
        color: theme.palette.neutralPrimary,
        fontWeight: FontWeights.semibold,
        margin: "0"
    },
    subHeading: {
        color: theme.palette.neutralSecondary,
        fontWeight: FontWeights.semibold,
        margin: "0"
    },
    body: {
        padding: "0 24px"
    }
});
