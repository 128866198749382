import * as React from "react";
import { Modal, IconButton, IIconProps, Stack, Separator } from "@fluentui/react";
import { contentStyles } from "./ConfidentialityAgreementDialog.style";

import agreementText from "./ConfidentialityAgreementText";
interface ConfidentialityAgreementDialogProps {
    hidden: boolean;
    onDismiss: () => void;
}

const ConfidentialityAgreementDialog: React.FC<ConfidentialityAgreementDialogProps> = ({ hidden, onDismiss }) => {
    const cancelIcon: IIconProps = { iconName: "Cancel" };

    return (
        <Modal isOpen={!hidden} onDismiss={onDismiss} isBlocking={false} containerClassName={contentStyles.container}>
            <Stack horizontal horizontalAlign="space-between" className={contentStyles.header}>
                <Stack>
                    <h2 className={contentStyles.heading}>
                        Security Intelligence Platform (SIP) Confidentiality Acknowledgement
                    </h2>
                    <h4 className={contentStyles.subHeading}>
                        MICROSOFT CORPORATION CONFIDENTIALITY AFFIRMATION FOR SIP DATA ACCESS AND USAGE
                    </h4>
                </Stack>
                <Stack.Item align="start">
                    <IconButton iconProps={cancelIcon} ariaLabel="Close popup modal" onClick={onDismiss} />
                </Stack.Item>
            </Stack>
            <Separator />
            <div className={contentStyles.body}>
                <p>{agreementText}</p>
            </div>
            <Separator />
        </Modal>
    );
};

export default ConfidentialityAgreementDialog;
