import { DataSetSummary } from "./DataSetSummary.types";

export type DataSetDetail = DataSetSummary & {
    accessPackageReferenceId: number;
    m365NotificationGroupName: string;
    securityGroupName: string;
    platformName: string;
    adlsId?: number;
    adlsBlobUri?: string;
    adlsDfsUri?: string;
    adlsDfsPath?: string;
    adlsRetentionInDays?: number;
    adlsStatus?: string;
    adlsUpdateFrequencyInHours?: number;
    adlsSLAInHours?: number;
    adlsFirstDateAvailable?: string;
    adlsTotalRecords?: number;
    adlsLatestRefresh?: string;
    adlsPartitionSchema?: string;
    adlsDataFormat?: string;
    kustoId?: number;
    kustoConnectionString?: string;
    kustoType?: string;
    kustoTable?: string;
    kustoDatabase?: string;
    kustoRetentionInDays?: number;
    kustoStatus?: string;
    sdwId?: number;
    sdwConnectionString?: string;
    sdwType?: string;
    sdwName?: string;
    sdwRetentionInDays?: number;
    sdwLatestRefresh?: Date;
    sdwFirstDateAvailable?: Date;
    sdwTotalRecords?: number;
    sdwStatus?: string;
    adlsSnapshotType?: string;
    sdwSnapshotType?: string;
    kustoSnapshotType?: string;
};

export type DataSetDetailProps = {
    dataset: DataSetDetail;
};

export type AdlsConnectionInfo = {
    account: string;
    path: string;
};

export type KustoConnectionInfo = {
    cluster: string;
    database: string;
    table: string;
};

export type SdwConnectionInfo = {
    cluster: string;
    database: string;
    name: string;
    type: string;
};

export type Contact = {
    name: string;
    objectId: string;
    email: string;
};

export enum SortByType {
    MyDataset = 0,
    Views = 1,
    Incidents = 2,
    MyFavorites = 3
}
