import { AssignmentRequest } from "../../features/DataSetCollections/DatasetCollection.type";
import { AccessRequest } from "../../features/DataSets/DatasetCardGrid/Types";
import { dataCatalogClient } from "../clients";
import { IContact } from "../models/Contact";
import { ISipApiResponse } from "../models/SipApiResponse";
import { SPNListResponse } from "../models/SPNListResponse";

export const searchSPN = async (filterText: String): Promise<ISipApiResponse<SPNListResponse>> => {
    const { data, status } = await dataCatalogClient.get<SPNListResponse>(`datacatalogfeature/searchspn/${filterText}`);
    return {
        data,
        message: "",
        status
    };
};

export type SearchType = "All" | "AllGroups" | "AADGroups" | "ADGroups" | "Users";
export const searchAAD = async (name: String, type: SearchType = "All"): Promise<ISipApiResponse<IContact[]>> => {
    const { data, status } = await dataCatalogClient.get<IContact[]>(
        `datacatalogfeature/searchAAD/${name}/?type=${type}`
    );
    return {
        data,
        message: "",
        status
    };
};

export const saveOBORequest = async (req: Partial<AccessRequest>): Promise<ISipApiResponse<AccessRequest>> => {
    const { data, status } = await dataCatalogClient.post<AccessRequest>(`datacatalogfeature/AddOBOAccessRequest`, req);

    return {
        data,
        message: "",
        status
    };
};
export const saveUserAccessRequest = async (id: String): Promise<ISipApiResponse<AccessRequest>> => {
    const { data, status } = await dataCatalogClient.post<AccessRequest>(`datacatalogfeature/userAccessRequest/${id}`, id);

    return {
        data,
        message: "",
        status
    };
};
