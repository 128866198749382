import { Depths, IContextualMenuItem, IDocumentCardStyles, IStackStyles, mergeStyleSets, Stack } from "@fluentui/react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import LoadingDataSetCard from "./LoadingDataSetCard";
import { saveUserAccessRequest } from "../../../common/api/OBOAccessRequestIntakeApi";
export const cardStackStyles: IStackStyles = {
    root: {
        width: `500`,
        padding: "20px"
    }
};

export const getShimmerStyles = () => {
    return {
        root: {
            width: "50%"
        },
        shimmerWrapper: [
            {
                backgroundColor: "#deecf9",
                zIndex: 9999
            }
        ],
        shimmerGradient: [
            {
                backgroundColor: "#deecf9",
                backgroundImage:
                    "linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #c7e0f4 50%, rgba(255, 255, 255, 0) 100%)"
            }
        ]
    };
};

export type CardDimensionProps = {
    cardWidth: number;
    cardHeight: number;
    cardMaxWidth: number;
};

export function getCardStyles({ cardWidth, cardHeight, cardMaxWidth }: CardDimensionProps): IDocumentCardStyles {
    return {
        root: {
            display: "inline-block",
            marginRight: "5px",
            marginBottom: "5px",
            width: cardWidth,
            height: cardHeight,
            maxWidth: cardMaxWidth,
            transition: "box-shadow .3s",
            ":hover": {
                boxShadow: Depths.depth16
            },
            fontFamily: "Helvetica"
        }
    };
}

export const loadingClassNames = mergeStyleSets({
    loading: {
        filter: "blur(10px)",
        clipPath: "inset(0)"
    },
    loaded: {
        filter: "blur(0px)",
        transition: "filter 1.5s linear"
    }
});

export function selfRequest(id: string): IContextualMenuItem {
    return {
        key: "selfRequest",
        text: "User Account",
        iconProps: {
            iconName: "FollowUser"
        },
        title: "User Account",

        onClick: () => {
            try {
                saveUserAccessRequest(id);
            } catch (Exception) {}
            window.open(
                `https://myaccess.microsoft.com/@microsoft.onmicrosoft.com#/access-packages/${id}`,
                "_blank",
                "noopener,noreferrer"
            );
        }
    };
}

export function oboRequest(
    aPkgRefId: number,
    id: string,
    aPkgeName: string,
    navigate: NavigateFunction
): IContextualMenuItem {
    return {
        key: "oboRequest",
        iconProps: {
            iconName: "SecurityGroup"
        },
        title: "Service Principal",
        text: "Service Principal",
        onClick: () => {
            navigate(`/OBOAccessRequest/${aPkgRefId}/${id}/${aPkgeName}`);
        }
    };
}

export function oboRoleGroupRequest(
    collectionAccessPackageId: string,
    navigate: NavigateFunction,
    roleGroupStatus?: string
): IContextualMenuItem {
    return {
        key: "oboRoleGroupRequest",
        iconProps: {
            iconName: "HomeGroup"
        },
        disabled: roleGroupStatus !== "Unknown",
        title: "Role Group",
        text: `Role Group${getRoleGroupStatusDisplayName(roleGroupStatus)}`,
        onClick: () => {
            navigate(`/OBORoleGroupAccessRequest/${collectionAccessPackageId}`);
        }
    };
}

function getRoleGroupStatusDisplayName(status?: string): string {
    if (!status || status === "Unknown") return "";

    switch (status) {
        case "PendingApproval":
            return " (Pending Approval)";
        case "Delivered":
            return " (Granted)";
        default:
            return ` (${status})`;
    }
}
export const tagClassNames = mergeStyleSets({
    tag: {
        background: "#eee",
        borderRadius: "3px 0 0 3px",
        color: "#686868",
        display: "inline-block",
        height: "26px",
        lineHeight: "26px",
        padding: "0 20px 0 23px",
        position: "relative",
        margin: "0 10px 10px 0",
        textDecoration: "none",
        transition: "color 0.2s",
        "::before": {
            background: "#fff",
            borderRadius: "10px",
            boxShadow: "inset 0 1px rgba(0, 0, 0, 0.25)",
            content: "''",
            height: "6px",
            left: "10px",
            position: "absolute",
            width: "6px",
            top: "10px"
        },
        "::after": {
            background: "#fff",
            borderBottom: "13px solid transparent",
            borderLeft: "10px solid #eee",
            borderTop: "13px solid transparent",
            content: "''",
            position: "absolute",
            right: "0",
            top: "0"
        }
    },
    taglist: { padding: "5px", width: "300px" }
});

export function getEmptyCards({ cardWidth, cardHeight, cardMaxWidth }: CardDimensionProps) {
    return Array(100)
        .fill(0)
        .map((_, i) => (
            <Stack.Item style={{ width: cardWidth, maxWidth: cardMaxWidth, height: cardHeight }} key={`${i}_emptyDs`}>
                <LoadingDataSetCard />
            </Stack.Item>
        ));
}
