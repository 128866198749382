import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../common/state";
import { Suggestion } from "./SuggestionSearchBox";
import { DataSetSummary } from "../DataSets/DataSetSummary.types";
import { SearchByColumnResult, SearchByPathResult } from "../DataSets";

type SearchFilterState = {
    searchFilter: string;
    suggestionClicked: boolean;
    suggestions: Suggestion[];
    filteredDatasets: DataSetSummary[];
    columnSuggestions: SearchByColumnResult[];
    pathSuggestions: SearchByPathResult[];
};

const initialState: SearchFilterState = {
    searchFilter: "",
    suggestionClicked: false,
    suggestions: [],
    filteredDatasets: [],
    columnSuggestions: [],
    pathSuggestions: []
};

const searchFilterSlice = createSlice({
    name: "searchFilter",
    initialState,
    reducers: {
        setSearchFilter(state, action: PayloadAction<string | undefined>) {
            if (action.payload) {
                state.searchFilter = action.payload.trim();
            } else {
                state.searchFilter = "";
            }
        },
        setSuggestionClicked(state, action: PayloadAction<boolean>) {
            state.suggestionClicked = action.payload;
        },
        setSuggestions(state, action: PayloadAction<Suggestion[]>) {
            state.suggestions = action.payload;
        },
        setFilteredDatasets(state, action: PayloadAction<DataSetSummary[]>) {
            state.filteredDatasets = action.payload;
        },
        clearSuggestions(state) {
            state.suggestions = [];
        },
        setColumnSuggestions(state, action: PayloadAction<SearchByColumnResult[]>) {
            state.columnSuggestions = action.payload;
        },
        setPathSuggestions(state, action: PayloadAction<SearchByPathResult[]>) {
            state.pathSuggestions = action.payload;
        }
    }
});

export const {
    setSearchFilter,
    setSuggestionClicked,
    setSuggestions,
    clearSuggestions,
    setFilteredDatasets,
    setColumnSuggestions,
    setPathSuggestions
} = searchFilterSlice.actions;
export default searchFilterSlice.reducer;
export const searchFilter = (state: RootState) => state.searchFilter;
