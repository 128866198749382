import * as React from "react";
import { Stack, Text, PrimaryButton, MessageBar, MessageBarType } from "@fluentui/react";
import { useState, useEffect } from "react";
import { LoadingOverLay } from "@sipmon/reactcomponents";
import Wrapper from "./Wrapper";
import UserAccessRequestForm from "./UserAccessRequestForm";
import { checkAccess, getPlatformDetails, selectUserAccessRequest } from "./slice";
import { useAppDispatch, useAppSelector } from "../../common/state";
import { SipMonModal } from "../SipMonModal";
import { confirmationContent } from "../../common/utils";
import { useMsal } from "@azure/msal-react";
import { AccessRoles, authenticationParameters } from "../../common/auth";
import { AccountInfo } from "@azure/msal-browser";

const UnAuthenticatedPage: React.FC = () => {
    const { instance } = useMsal();
    const {
        isCheckAccessLoading,
        checkAccessMessage,
        platformDetails,
        isRequestLoading,
        isRequestSuccess,
        requestError
    } = useAppSelector(selectUserAccessRequest);
    const dispatch = useAppDispatch();
    const [existing, setExisting] = useState<{ message: string }>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    useEffect(() => {
        if (isRequestSuccess || isRequestLoading) {
            setShowConfirmationModal(true);
        }
    }, [isRequestSuccess, isRequestLoading]);

    useEffect(() => {
        if (isRequestSuccess && !showConfirmationModal) {
            setTimeout(() => {
                dispatch(checkAccess());
            }, 2000);
        }
    }, [showConfirmationModal, isRequestSuccess]);

    useEffect(() => {
        dispatch(getPlatformDetails());
    }, []);

    async function refreshToken(account: AccountInfo) {
        const request = { ...authenticationParameters, account: account };
        await instance.acquireTokenRedirect(request);
        dispatch(checkAccess());
    }

    useEffect(() => {
        if (checkAccessMessage) {
            setExisting({ message: checkAccessMessage });
            if (checkAccessMessage === "You have an existing assignment.") {
                const account = instance.getActiveAccount();
                if (account) {
                    if (account.idTokenClaims?.roles) {
                        const intersection = account.idTokenClaims.roles?.filter((role) => AccessRoles.includes(role));
                        if (intersection.length > 0) {
                            window.location.reload();
                        } else {
                            refreshToken(account);
                        }
                    }
                }
            }
        }
    }, [checkAccessMessage]);

    useEffect(() => {
        dispatch(checkAccess());
        const intervalId = setInterval(() => {
            dispatch(checkAccess());
        }, 5 * 60 * 1000); // 5 minutes in milliseconds

        // Clear interval on component unmount
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return (
        <div style={{ height: "100vh" }}>
            <Wrapper>
                {requestError && <MessageBar messageBarType={MessageBarType.severeWarning}>{requestError}</MessageBar>}
                <LoadingOverLay isOverlayVisible={showModal} />
                <Text variant="xxLarge">Welcome to the SIP Data Catalog!</Text>
                {showModal && platformDetails && (
                    <UserAccessRequestForm
                        platformDetails={platformDetails}
                        closeModal={() => {
                            setShowModal(false);
                        }}
                    />
                )}
                {isCheckAccessLoading && (
                    <Stack horizontalAlign="start" horizontal>
                        <Stack.Item style={{ width: "50%" }}></Stack.Item>
                        <Text style={{ width: "200px" }} className="typewriter" variant="large">
                            Checking your access
                        </Text>
                    </Stack>
                )}
                {showConfirmationModal && !requestError && (
                    <SipMonModal
                        headerText={"SIP - Access Request"}
                        content={confirmationContent(() => setShowConfirmationModal(false))}
                        isLoading={isRequestLoading}
                        loadingLabel={"Please wait, submitting..."}
                        onDismiss={() => setShowConfirmationModal(false)}
                    />
                )}
                {!existing && !isCheckAccessLoading && <Text variant="large">Looks like you don't have access.</Text>}
                {existing && !isCheckAccessLoading && (
                    <Text styles={{ root: { textAlign: "center" } }} block variant="large">
                        {existing.message}
                    </Text>
                )}
                <PrimaryButton
                    disabled={!!existing || isCheckAccessLoading || !platformDetails}
                    text="Request Access"
                    onClick={() => setShowModal(true)}
                />
            </Wrapper>
        </div>
    );
};

export default UnAuthenticatedPage;
