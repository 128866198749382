import { Stack, Separator } from "@fluentui/react";
import { FormTextField } from "../../../common/components/FormTextField";
import { DatasetContentProps } from "../DatasetCardGrid/Types";

function formatDate(dateValue) {
    return dateValue != null && dateValue !== "1900-01-01T00:00:00+00:00" ? dateValue.split("T")[0] : "Not Available";
}
export function SdwDatasetContent({ currentDataSet }: DatasetContentProps) {
    return (
        <Stack>
            <Separator>Connection Details</Separator>

            <FormTextField label="Connection String" txt={currentDataSet.sdwConnectionString} />
            <FormTextField label="Type" txt={currentDataSet.sdwType} />
            <FormTextField label="Name" txt={currentDataSet.sdwName} />
            <Separator>Additional Information</Separator>
            <FormTextField label="First Date Available" txt={formatDate(currentDataSet.sdwFirstDateAvailable)} />
            <FormTextField label="Last Refresh" txt={formatDate(currentDataSet.sdwLatestRefresh)} />
            <FormTextField label="Last Ingested Count" txt={currentDataSet.sdwTotalRecords ? currentDataSet.sdwTotalRecords.toString() : "Not Available"} />
            <FormTextField
                label="Retention Period"
                txt={`${currentDataSet.sdwRetentionInDays} ${
                    currentDataSet.sdwRetentionInDays && currentDataSet.sdwRetentionInDays > 1 ? "Days" : "Day"
                }`}
            />
            {currentDataSet.sdwSnapshotType && (
                <FormTextField label="Snapshot Type" txt={currentDataSet.sdwSnapshotType} />
            )}
        </Stack>
    );
}
